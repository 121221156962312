import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './style.css';

// Router
import router from './router/router';
const app = createApp(App)

// Use plugins
app.use(router)  // Integrate vue-router
// Mount app
app.mount('#app')
