// src/services/AuthService.ts
import axios from "axios";

export interface LoginResponse {
  id: string;
  email: string;
  name: string;
  role: string;
  accessToken: string;
  branch: string;
  ok: boolean;
}

export interface LoginCredentials {
  name: string;
  password: string;
}
export interface LoginCredentials2 {
  username: string;
  password: string;
}

export class AuthService {
  private baseURL = process.env.VUE_APP_BASE_URL;


  public async loginManager(credentials: LoginCredentials): Promise<LoginResponse> {
    try {
      const response = await axios.post(`https://colonie-v2-67bf87a86c24.herokuapp.com/auth/login/employee`, credentials);
      localStorage.setItem("id", response.data.id);
      localStorage.setItem("userToken", response.data.accessToken);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("type", 'employee');
      localStorage.setItem("userBranch", response.data.branch_id); // Store the branch value
      // localStorage.setItem("role", response.data.role);
      return response.data;
    } catch (error) {
      console.log(error)
      console.error("Login failed:", error);
      throw error;
    }
  }

  public async loginChild(credentials: LoginCredentials2): Promise<LoginResponse> {
    try {
      const response = await axios.post(`https://colonie-v2-67bf87a86c24.herokuapp.com/auth/login/child`, credentials);
      localStorage.setItem("id", response.data.registrationId);
      localStorage.setItem("userToken", response.data.accessToken);
      localStorage.setItem("username", response.data.username);
      localStorage.setItem("type", 'child');
      return response.data;
    } catch (error) {
      console.error("Child login failed:", error);
      throw error;
    }
  }

  getToken() {
    return localStorage.getItem('token') || localStorage.getItem('userToken'); // Adjust if the token is stored elsewhere
  }

  getId() {
    return localStorage.getItem('id');
  }

  isLoggedIn() {
    const token = this.getToken();
    return !!token;
  }

  public logout(): void {
    localStorage.clear();
  }

  async getUser() {
    const token = this.getToken();
    if (!token) return null;

    try {
      const id = this.getId()
      const response = await axios.get(`${this.baseURL}/auth/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      return null;
    }
  }

  public isAdmin() {
    const userRole = localStorage.getItem('role');
    console.log(userRole)
    return userRole === 'admin';
  }


}