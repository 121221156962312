import { createRouter, createWebHistory } from 'vue-router';
import { AuthService } from '@/services/AuthService';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/presentation/Home/Home.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/presentation/Home/Login/Login.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/check-in',
    name: 'checkin',
    component: () => import('@/presentation/Home/Checkin/Checkin.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/check-out',
    name: 'checkout',
    component: () => import('@/presentation/Home/Checkout/Checkout.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/home-cms',
    name: 'home-cms',
    component: () => import('@/presentation/Home/Home.vue'),
    meta: { requiresAuth: true },
  },
  {
    component: () => import('@/presentation/Manager/Login/Login.vue'),
    name: 'Login-manager',
    path: '/manager/login',
    meta: { requiresAuth: false },
  },
  {
    component: () => import('@/presentation/Manager/ConfirmationsPage.vue'),
    name: 'confirmation',
    path: '/confirmation',
    meta: { requiresAuth: true },
  },
  {
    component: () => import('@/presentation/Administrator/Home/Home.vue'),
    name: 'admin-home',
    path: '/admin',
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    component: () => import('@/presentation/Administrator/Employees/Employees.vue'),
    name: 'admin-employees',
    path: '/admin/employees',
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    component: () => import('@/presentation/Administrator/Children/Children.vue'),
    name: 'admin-children',
    path: '/admin/children',
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    component: () => import('@/presentation/Administrator/Children/Child.vue'),
    name: 'admin-child',
    path: '/admin/children/:id',
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    component: () => import('@/presentation/Administrator/Attendance/Attendance.vue'),
    name: 'admin-activity',
    path: '/admin/activity',
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    component: () => import('@/presentation/Register/Register.vue'),
    name: 'register-child',
    path: '/register',
    meta: { requiresAuth: false},
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authService = new AuthService();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authService.isLoggedIn()) {
      next({ name: 'login' });  // Changed 'Login' to 'login'
    } else if (to.matched.some(record => record.meta.requiresAdmin) && !authService.isAdmin()) {
      next({ name: 'home' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
