<template>
  <!-- <div>
    <Sidebar />
  </div> -->
  <router-view>
  </router-view>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  setup () {
    

    return {}
  },
  // components: {Sidebar}
  components: {}
})
</script>

<style scoped>

</style>